import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { baseURL } from "../src/config";
import { NO_PUBLIC_KEY_ERROR } from "./global-state";

// Grafana Faro logs initialisation
import {
  getWebInstrumentations,
  initializeFaro,
  faro,
} from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

const faroUrl = window.location.hostname.includes("prod")
  ? "https://faro-collector-prod-eu-west-3.grafana.net/collect/b34d45087d1c27a11d98ea9bf5e2efa6"
  : "https://faro-collector-us-central2.grafana.net/collect/b2c8fc942ffc3bc1793844e323a185f5";

initializeFaro({
  url: faroUrl,
  app: {
    name: "frontend-emulator",
    version: "1.0.0",
    environment: "production",
  },
  instrumentations: [
    // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
    ...getWebInstrumentations(),

    // Initialization of the tracing package.
    // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
    new TracingInstrumentation(),
  ],
});

const searchParams = new URLSearchParams(location.search);
const bundlerUrl = searchParams.get("ingress_name") || null;
const iosIngressUrl = searchParams.get("ios_ingress_name") || null;
const androidIngressUrl = searchParams.get("android_ingress_name") || null;
const androidPublicKey = searchParams.get("android_public_key") || null;
const iosPublicKey = searchParams.get("ios_public_key") || null;
const dontShowLoadingAnimation = searchParams.get("no_animation") || null;

!androidPublicKey &&
  !iosPublicKey &&
  faro.api.pushError(new Error(NO_PUBLIC_KEY_ERROR));

const appProps = { baseURL, bundlerUrl, androidPublicKey, iosPublicKey, iosIngressUrl, androidIngressUrl, dontShowLoadingAnimation };

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App {...appProps} />
  </React.StrictMode>,
);
