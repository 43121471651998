import React, { useLayoutEffect, useState } from "react";
import { removeMessages } from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { Toast } from "../../../shared";
import "./mid-pane-messages.scss";

export const MidPaneMessages = () => {
  const [ui, dispatch] = useAppContext((state) => state.ui);
  const { isLightMode, messages } = ui;

  const [shouldLimitHeight, setShouldLimitHeight] = useState(false);

  useLayoutEffect(() => {
    messages.length && setShouldLimitHeight(!isLastMessageInView());
  }, [messages]);

  const isLastMessageInView = () => {
    const appContainerRect = document
      .querySelector("div#root")
      .getBoundingClientRect();

    const lastMessage = document.querySelector(
      "div.message-container > div:last-child",
    );
    const lastMessageRect = lastMessage.getBoundingClientRect();

    const isLastMessageVisible =
      lastMessageRect.top >= appContainerRect.top &&
      lastMessageRect.left >= appContainerRect.left &&
      lastMessageRect.bottom <= appContainerRect.bottom &&
      lastMessageRect.right <= appContainerRect.right;

    return isLastMessageVisible;
  };

  const onClose = (messageId) => {
    const message = messages.find(({ id }) => id === messageId);
    dispatch(removeMessages([message]));
  };

  return (
    !!messages.length && (
      <div className="relative w-full">
        <div
          className={`message-container w-full px-7 ${
            shouldLimitHeight && "fixed-height-message-container"
          } flex flex-col gap-y-3 absolute top-0 left-0 z-40 overflow-y-auto ${
            isLightMode ? "light-scrollbar" : "dark-scrollbar"
          }`}
        >
          {messages.map(({ id, message, isError }) => (
            <Toast
              key={id}
              messageId={id}
              message={message}
              isError={isError}
              onClose={onClose}
            />
          ))}
        </div>
      </div>
    )
  );
};
