import { faro } from "@grafana/faro-web-sdk";
import React from "react";
import { setMessages } from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { FooterButton } from "../FooterButton";
import { EmulatorHelperService } from "../../../../services";

export const DownloadLogs = ({ logsByDuplicacy }) => {
  const [ui, dispatch] = useAppContext((state) => state.ui);
  const { selectedTab } = ui;

  const getDownloadableLogs = () =>
    logsByDuplicacy[
      selectedTab === "debug-tab" ? "debugLogs" : "metroBundlerLogs"
    ].map(({ appearanceCount, log }) => `> ${appearanceCount}x :-  ${log}\n\n`);

  const onDownloadButtonClick = () => {
    faro.api.pushEvent("click_download_logs_button");

    const element = document.createElement("a");

    const file = new Blob(getDownloadableLogs(), {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);

    const date = new Date();
    const fileTimeSuffix = `${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;
    element.download = `${
      selectedTab === "debug-tab" ? "debug_logs" : "metro_bundler_logs"
    }_${fileTimeSuffix}`;

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    dispatch(
      setMessages([
        EmulatorHelperService.getMessage(
          "Logs downloaded. Please check the Downloads folder.",
        ),
      ]),
    );
    faro.api.pushLog(["Logs downloaded successfully."]);
  };

  return (
    <FooterButton
      title="Download Logs"
      logsByDuplicacy={logsByDuplicacy}
      extraClasses={["transform", "rotate-90"]}
      onClick={onDownloadButtonClick}
    >
      &#8677;
    </FooterButton>
  );
};
