import { DataTransformerService } from "../DataTransformerService";

export class AdminApiService {
  static getEnvironment() {
    return window.location.host.includes(".prod.") ? "prod" : "other";
  }

  static getIDEServiceUrl() {
    const env = this.getEnvironment();
    return env === "prod"
      ? "https://ide-service.bxide-svc.prod.centralindia.az.svc.builder.ai"
      : "https://ide-service.bxide-svc.stage.eastus.az.svc.builder.ai";
  }

  static async getDefaultAppStatus(instanceName, signal) {
    const ideServiceUrl = this.getIDEServiceUrl();

    const response = await fetch(
      `${ideServiceUrl}/v2/instances/${instanceName}/emulator`,
      { signal },
    );
    const result = await response.json();

    return !result.error
      ? DataTransformerService.getDefaultAppStatus(result)
      : { error: result.error.message };
  }
}
