import React, { useLayoutEffect, useReducer, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { LeftSidebar, MidPane, RightSidebar, Sidebar } from "./components";
import {
  appReducer,
  initialState,
  appContext,
  setIdeDetails,
} from "./global-state";

const AppContextProvider = appContext.Provider;

const App = ({ bundlerUrl, androidPublicKey, iosPublicKey, iosIngressUrl, androidIngressUrl, dontShowLoadingAnimation }) => {
  /**
   * Handling the entire application/global state.
   */
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { isLightMode, isRightSidebarCollapsed } = state.ui;

  const [isResizeHandleDisabled, setIsResizeHandleDisabled] = useState(false);
  const [isRightSidebarCollapsible, setIsRightSidebarCollapsible] =
    useState(false);

  const rightSidebarRef = useRef(null);

  useLayoutEffect(() => {
    dispatch(
      setIdeDetails({
        bundlerUrl,
        androidPublicKey,
        iosPublicKey,
        iosIngressUrl,
        androidIngressUrl,
        dontShowLoadingAnimation,
      })
    );
  }, []);

  useLayoutEffect(() => {
    const portalRootClassList =
      document.getElementById("portal-root").classList;
    isLightMode
      ? portalRootClassList.remove("dark")
      : portalRootClassList.add("dark");
  }, [isLightMode]);

  useLayoutEffect(() => {
    setIsRightSidebarCollapsible(isRightSidebarCollapsed);

    isRightSidebarCollapsed && isRightSidebarCollapsible
      ? rightSidebarRef.current.collapse()
      : rightSidebarRef.current.expand();

    setIsResizeHandleDisabled(isRightSidebarCollapsed);
  }, [isRightSidebarCollapsed, isRightSidebarCollapsible]);

  return (
    <div className={`h-full w-full flex ${!isLightMode && "dark"}`}>
      <AppContextProvider value={{ state, dispatch }}>
        <Sidebar isLeftSidebar={true}>
          <LeftSidebar />
        </Sidebar>

        <PanelGroup direction="horizontal">
          <Panel defaultSize={70} minSize={25}>
            <MidPane />
          </Panel>

          <PanelResizeHandle
            disabled={isResizeHandleDisabled}
            className={`w-1.5 flex justify-center items-center ${
              isResizeHandleDisabled
                ? "bg-dropdownBorder"
                : "bg-ideLogoLight dark:bg-ideLogoDark"
            }`}
            style={{
              cursor: isResizeHandleDisabled ? "not-allowed" : "ew-resize",
            }}
          >
            <span className=" text-primaryDark dark:text-backgroundSecondaryDark">
              &#9553;
            </span>
          </PanelResizeHandle>

          <Panel
            defaultSize={30}
            minSize={25}
            collapsible={isRightSidebarCollapsible}
            collapsedSize={1}
            style={{ overflow: "visible" }}
            className=" transition-flexGrow duration-0.45 ease-linear"
            ref={rightSidebarRef}
          >
            <Sidebar isLeftSidebar={false}>
              <RightSidebar />
            </Sidebar>
          </Panel>
        </PanelGroup>
      </AppContextProvider>
    </div>
  );
};

export default App;
