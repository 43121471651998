import {
  SET_THEME_ACTION,
  SET_LEFT_SIDEBAR_STATUS,
  SET_RIGHT_SIDEBAR_STATUS,
  SET_MESSAGES,
  REMOVE_MESSAGES,
  SET_SELECTED_TAB,
  SET_GENERAL_INFO_MODAL_STATUS,
  SET_DEFAULT_APP_MODAL_STATUS,
  SET_AUTOMATED_STEPS_FAILURE_MODAL_STATUS,
} from "../actions";

export const uiReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_THEME_ACTION:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLightMode: payload,
        },
      };
    case SET_LEFT_SIDEBAR_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLeftSidebarCollapsed: payload,
        },
      };
    case SET_RIGHT_SIDEBAR_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isRightSidebarCollapsed: payload,
        },
      };
    case SET_MESSAGES:
      return {
        ...state,
        ui: {
          ...state.ui,
          messages: [...payload, ...state.ui.messages],
        },
      };
    case REMOVE_MESSAGES:
      return {
        ...state,
        ui: {
          ...state.ui,
          messages: [
            ...state.ui.messages.filter(
              (message) =>
                !payload.find(
                  (payloadMessage) => payloadMessage.id === message.id,
                ),
            ),
          ],
        },
      };
    case SET_SELECTED_TAB:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedTab: payload,
        },
      };
    case SET_GENERAL_INFO_MODAL_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isGeneralInfoModalOpen: payload,
        },
      };
    case SET_DEFAULT_APP_MODAL_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDefaultAppModalOpen: payload,
        },
      };
    case SET_AUTOMATED_STEPS_FAILURE_MODAL_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isAutomatedStepsFailureModalOpen: payload,
        },
      };
    default:
      return state;
  }
};
