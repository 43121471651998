import React from "react";
import {
  setAutomatedStepsFailureModalStatus,
  BUNDLER_AUTOMATED_STEPS_FAILURE_ERROR,
} from "../../../../../../global-state";
import { useAppContext } from "../../../../../../hooks";
import { Modal } from "../../../../../shared";

export const AutomatedStepsFailureModal = () => {
  const [ui, dispatch] = useAppContext((state) => state.ui);
  const { isAutomatedStepsFailureModalOpen } = ui;

  const getAutomatedStepsFailureModalContent = () => {
    const messageTokens = BUNDLER_AUTOMATED_STEPS_FAILURE_ERROR.split(":");

    return (
      <div className="text-sm text-slate-900 dark:text-secondaryDark">
        <p>
          {messageTokens.map((token, index) =>
            index === 0 ? (
              <b key={token}>{token}:</b>
            ) : (
              <span key={token}>{token}</span>
            ),
          )}
        </p>
        <br />
        <p>
          <b>
            Firstly, please load the app on STATIC mode, then accept any app
            permission pop-ups, then try the LOCAL mode again.
          </b>
          .
        </p>
      </div>
    );
  };

  const onAutomatedStepsFailureModalClose = () => {
    dispatch(setAutomatedStepsFailureModalStatus(false));
  };

  return (
    <Modal
      isOpen={isAutomatedStepsFailureModalOpen}
      title={`Failed to Automate Bundler Connection`}
      content={getAutomatedStepsFailureModalContent()}
      onClose={onAutomatedStepsFailureModalClose}
    />
  );
};
