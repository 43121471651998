import { faro } from "@grafana/faro-web-sdk";
import React, { useState } from "react";
// import shake from "../../../../assets/images/shake.png";
import { setMessages, DEVICE_SHAKE_FAILURE } from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { EmulatorHelperService } from "../../../../services";

export const Shake = () => {
  const [appetize, emulatorOptions, dispatch] = useAppContext(
    (state) => state.appetize,
    (state) => state.emulatorOptions,
  );
  const { session, isConfiguringBundler } = appetize;
  const { os } = emulatorOptions.selected;

  const [isShaking, setIsShaking] = useState(false);

  const onShakeButtonClick = async () => {
    try {
      faro.api.pushEvent("click_shake_button");

      setIsShaking(true);

      os === "android"
        ? await session.keypress("ANDROID_KEYCODE_MENU")
        : await session.shake();

      faro.api.pushLog(["Device shake successful."]);
    } catch (error) {
      const deviceShakeFailureError = EmulatorHelperService.getMessage(
        DEVICE_SHAKE_FAILURE,
        true,
      );

      dispatch(setMessages([deviceShakeFailureError]));
      faro.api.pushError(new Error(deviceShakeFailureError.message));
    } finally {
      setIsShaking(false);
    }
  };

  return (
    <button
      className={`w-1/2 flex justify-center items-center border-r border-solid border-dropdownBorder dark:border-borderDark text-sm ${
        session && !isShaking && !isConfiguringBundler
          ? "text-dropdownOption dark:text-secondaryDark bg-white dark:bg-backgroundPrimaryDark hover:font-medium"
          : "text-inactiveTextColour dark:text-disabledDark bg-toggleDisabledBackground dark:bg-backgroundSecondaryDark"
      }`}
      disabled={!session || isShaking || isConfiguringBundler}
      onClick={onShakeButtonClick}
    >
      <span>Shake</span>
      {/* <img src={shake} title="Shake Device" className="max-w-[24px]" /> */}
    </button>
  );
};

/**
 * Apply class -> "max-xl:hidden mr-2" to Shake text when SVG for Shake Device is available.
 * This class hides the text from XL and smaller devices.
 *
 * Commented out Shake Device icon as it's not available as of now.
 */
